<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/imgs/neo-logo.png" width="150" />
    <br />
    <h3>(404) Page NotFound</h3>
  </div>
</template>

<script>
export default {};
</script>